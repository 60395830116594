import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';

class IndustryPage extends Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { contentfulIndustry: page } = data;

    const variations = {
      dark: page.dark,
      numberedListBg: page.featuredNumberedListBackgroundColor,
      numberedListText: page.featuredNumberedListTextColor,
      headerBackgroundColor: page.industryHeaderBackgroundColor,
      headerTextColor: page.industryHeaderTextColor,
      headerLabelColor: page.headerLabelColour,
      listNumberColor: page.listNumberColour,
    };

    const renderedSections = page.sections.map((section, i) =>
      sectionConductor(section, i, variations)
    );

    return (
      <div className="page page--industry">
        <Layout pathname={location.pathname}>
          <SEO
            title={page.title}
            description="We team up with leading clients helping them drive change where it matters most"
            metadata={page.metadata}
            routeName="industry:detail"
            routeParams={{ industrySlug: page.slug }}
          />
          {renderedSections}
        </Layout>
      </div>
    );
  }
}

export default IndustryPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulIndustry(slug: { eq: $slug }) {
      title
      slug
      dark
      industryHeaderBackgroundColor
      industryHeaderTextColor
      featuredNumberedListBackgroundColor
      featuredNumberedListTextColor
      headerLabelColour
      listNumberColour
      metadata {
        ...Metadata
      }
      ...allIndustrySectionsFragment
    }
  }
`;

IndustryPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
